body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.flex-panel{
  align-items: stretch;
  padding: 5px;
  flex-flow: row column;
  justify-content: space-around;
}

.gm-ui-hover-effect {
  display: none !important;
}

.react-datepicker-popper {
  z-index: 10000; 
}

.react-datepicker__navigation {
  z-index: 1;
}